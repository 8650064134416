import { render, staticRenderFns } from "./QImage.vue?vue&type=template&id=fdc73694&scoped=true&"
import script from "./QImage.vue?vue&type=script&lang=ts&"
export * from "./QImage.vue?vue&type=script&lang=ts&"
import style0 from "./QImage.vue?vue&type=style&index=0&id=fdc73694&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc73694",
  null
  
)

export default component.exports